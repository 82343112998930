import { color } from 'highcharts';
import theme from '../theme';
import {
  dashDelete,
  dateFrontConvert,
  dateMonthLibrary,
  dateMonthParse,
  dateTimeParse,
  numberFormatDecimals,
  numberFormatDecimalsCounting,
} from './functionHelpers';
import { max } from 'lodash';

export const headDefultStyles = {
  // color: 'white',
  backgroundColor: theme.palette.grey[50],
  verticalAlign: 'middle',
  paddingTop: 0.5,
  paddingLeft: 1,
  paddingRight: 1,
  // '& svg.MuiSvgIcon-root': {
  //   color: 'rgba(255,255,255,1) !important',
  // },
  div: {
    justifyContent: 'space-between',
    whiteSpace: 'normal',
    wordWrap: 'break-word',

    // '& button': {
    //   color: 'rgba(255,255,255,1)',
    // },
  },
  'div.Mui-TableHeadCell-Content-Wrapper': {
    lineHeight: '1rem',
    fontSize: '12px',
    fontWeight: 400,
  },
};

const redirectOrchard = (row) => {
  // console.log(row.original);
};

export const initialSorting = [
  {
    id: 'orchard',
    desc: false,
  },
];

// export const colsYieldCalculatorWinter = [
//   {
//     header: 'Temporada',
//     accessorKey: 'season',
//     maxSize: 90, // max size enforced during resizing
//   },
//   {
//     header: 'Cliente',
//     accessorKey: 'client',
//   },
//   {
//     header: 'Agrícola',
//     accessorKey: 'agroBusiness',
//     enableSorting: false,
//   },
//   {
//     header: 'Centro de Costos',
//     accessorKey: 'cc',
//   },
//   {
//     header: 'Huerto',
//     accessorKey: 'orchard',
//   },
//   {
//     header: 'Cuartel',
//     accessorKey: 'quarter',
//   },
//   {
//     header: 'Especie',
//     accessorKey: 'specie',
//     enableSorting: false,
//   },
//   {
//     header: 'Edad',
//     accessorKey: 'age',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Variedad',
//     accessorKey: 'variety',
//   },
//   {
//     header: 'Portainjerto',
//     accessorKey: 'rootStock',
//   },
//   {
//     header: 'Superficie',
//     accessorKey: 'surface',
//   },
//   {
//     header: 'Porción Frío acumulada',
//     accessorKey: 'pfAcumulated',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Plantas Totales',
//     accessorKey: 'totalPlants',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//   },
//   {
//     header: 'Plantas/ha',
//     accessorKey: 'plantsHa',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//   },
//   {
//     header: 'Flores / Dardo',
//     accessorKey: 'flowersDart',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores / Dardo Objetivo',
//     accessorKey: 'flowersDartFinalObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores / Ramilla',
//     accessorKey: 'flowersTwig',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores Objetivo/Ha',
//     accessorKey: 'flowersHaObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores Pre Poda/ha',
//     accessorKey: 'flowersHaPrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/ Yema/Dardo',
//     accessorKey: 'flowersBudDart',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Dardo/ha',
//     accessorKey: 'flowersDartHa',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Planta Objetivo',
//     accessorKey: 'flowersPlantObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Planta Pre Poda',
//     accessorKey: 'flowersPlantPrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Ramilla/ha',
//     accessorKey: 'flowersTwigsHa',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Plantas contadas prepoda',
//     accessorKey: 'plantCountPre',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Dardos prepoda/planta',
//     accessorKey: 'dartPrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Ramillas prepoda/planta',
//     accessorKey: 'twigPrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Yemas / Dardo',
//     accessorKey: 'budDart',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Ramillas Objetivo',
//     accessorKey: 'twigObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Dardos Objetivo',
//     accessorKey: 'dartObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Desyeme',
//     accessorKey: 'disbudding',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Dardos Post Poda/Planta',
//     accessorKey: 'dartPostPoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Dardo con desyeme',
//     accessorKey: 'flowersDartDisbudding',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores Post Poda/ha',
//     accessorKey: 'flowersHaPostPoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Planta Post Poda',
//     accessorKey: 'flowersPlantPostPoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Plantas contadas postpoda',
//     accessorKey: 'plantCountPost',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Ramillas Post Poda / Planta',
//     accessorKey: 'twigPostPoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Calibre Estimado (grs)',
//     accessorKey: 'caliberEstimate',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Calibre Objetivo (grs)',
//     accessorKey: 'caliberObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Calibre Real (grs)',
//     accessorKey: 'caliberReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Modelo',
//     accessorKey: 'fruitsetEstimate',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Objetivo',
//     accessorKey: 'fruitSetObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Rdto objetivo (kg/ha)',
//     accessorKey: 'yieldObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//     enableSorting: false,
//   },
//   {
//     header: 'Rdto estimado pre poda (kg/ha)',
//     accessorKey: 'yieldEstimatePrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//     enableSorting: false,
//   },

//   {
//     header: 'Rdto estimado post poda (Kg/ha)',
//     accessorKey: 'yieldEstimatePostPoda',
//     Cell: ({ renderedCellValue, row }) => {
//       let rdtoPostPoda = null;
//       rdtoPostPoda = renderedCellValue;
//       let colorIcon = '#4C5662';
//       let iconDeviation = '';
//       if (row.original?.yieldObjective && rdtoPostPoda) {
//         const topLimit = row.original?.yieldObjective * 1.15;
//         const lowLimit =
//           row.original?.yieldObjective - row.original?.yieldObjective * 0.1;

//         // const difference = row.original?.yieldObjective - rdtoPostPoda;

//         if (rdtoPostPoda > topLimit || rdtoPostPoda < lowLimit) {
//           colorIcon = '#ff6633';

//           iconDeviation = <i className='fas fa-circle'></i>;
//         }
//       }

//       return (
//         <span style={{ color: colorIcon }}>
//           {iconDeviation} {numberFormatDecimals(rdtoPostPoda, 1)}
//         </span>
//       );
//     },
//     enableSorting: false,
//   },
// ];

// export const colsYieldCalculatorFruit = [
//   {
//     header: 'Temporada',
//     accessorKey: 'season',
//     maxSize: 90, // max size enforced during resizing
//   },
//   {
//     header: 'Cliente',
//     accessorKey: 'client',
//   },
//   {
//     header: 'Agrícola',
//     accessorKey: 'agroBusiness',
//     enableSorting: false,
//   },
//   {
//     header: 'Centro de Costos',
//     accessorKey: 'cc',
//   },
//   {
//     header: 'Huerto',
//     accessorKey: 'orchard',
//   },
//   {
//     header: 'Cuartel',
//     accessorKey: 'quarter',
//   },
//   {
//     header: 'Especie',
//     accessorKey: 'specie',
//     enableSorting: false,
//   },
//   {
//     header: 'Edad',
//     accessorKey: 'age',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Variedad',
//     accessorKey: 'variety',
//   },
//   {
//     header: 'Portainjerto',
//     accessorKey: 'rootStock',
//   },
//   {
//     header: 'Superficie',
//     accessorKey: 'surface',
//   },
//   {
//     header: 'Plantas Totales',
//     accessorKey: 'totalPlants',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//   },
//   {
//     header: 'Plantas/ha',
//     accessorKey: 'plantsHa',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//   },
//   {
//     header: 'Frutos/Dardo (conteo centros frutales)',
//     accessorKey: 'fruitsDartEstimate',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Conteo',
//     accessorKey: 'fruitsDartCounter',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Máximo',
//     accessorKey: 'fruitsDartMax',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Objetivo',
//     accessorKey: 'fruitsDartObjetive',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Objetivo',
//     accessorKey: 'fruitsDartEstimateObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Pre Raleo',
//     accessorKey: 'fruitsDartPreRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Real',
//     accessorKey: 'fruitsDartReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/ha (rendimiento)',
//     accessorKey: 'fruitsHaYield',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/ha (preraleo)',
//     accessorKey: 'fruitsHaPreRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Planta (preraleo)',
//     accessorKey: 'fruitsPlantPreRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/planta (rendimiento)',
//     accessorKey: 'fruitsPlantYield',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Planta Objetivo',
//     accessorKey: 'fruitsPlantObjective',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Plantas contadas preraleo',
//     accessorKey: 'plantCountPreFruit',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Pauta de raleo',
//     accessorKey: 'guidelineThinning',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Plantas contadas postraleo',
//     accessorKey: 'plantCountPostFruit',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Post Raleo',
//     accessorKey: 'fruitsDartPostRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Planta (postraleo)',
//     accessorKey: 'fruitsPlantPostRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Modelo',
//     accessorKey: 'fruitsetEstimate',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Objetivo',
//     accessorKey: 'fruitSetObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Real',
//     accessorKey: 'fruitsSetReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Real (est. por rdto.)',
//     accessorKey: 'fruitsSetRealYield',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Calibre Estimado (grs)',
//     accessorKey: 'caliberEstimate',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Calibre Objetivo (grs)',
//     accessorKey: 'caliberObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Calibre Real (grs)',
//     accessorKey: 'caliberReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Rdto estimado Conteo Frutos Pre Raleo (Kg/ha)',
//     accessorKey: 'yieldEstimateCounterFruitsPreRaleo',
//     // Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     Cell: ({ renderedCellValue, row }) => {
//       let rdtoPreRaleo = null;
//       rdtoPreRaleo = renderedCellValue;
//       let colorIcon = '#4C5662';
//       let iconDeviation = '';
//       if (row.original?.yieldEstimatePostPoda && rdtoPreRaleo) {
//         const topLimitPreRaleo = row.original?.yieldEstimatePostPoda * 1.1;
//         const lowLimitPreRaleo =
//           row.original?.yieldEstimatePostPoda -
//           row.original?.yieldEstimatePostPoda * 0.1;

//         if (rdtoPreRaleo > topLimitPreRaleo) {
//           colorIcon = '#1772c1';
//           // azul

//           iconDeviation = <i className='fas fa-circle'></i>;
//         }

//         if (rdtoPreRaleo < lowLimitPreRaleo) {
//           colorIcon = '#ff6633';

//           iconDeviation = <i className='fas fa-circle'></i>;
//         }
//       }

//       return (
//         <span style={{ color: colorIcon }}>
//           {iconDeviation} {numberFormatDecimals(rdtoPreRaleo, 1)}
//         </span>
//       );
//     },
//     enableSorting: false,
//   },
//   {
//     header: 'Rdto objetivo (kg/ha)',
//     accessorKey: 'yieldObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//     enableSorting: false,
//   },
//   {
//     header: 'Rdto estimado Conteo Frutos Post Raleo (Kg/ha)',
//     accessorKey: 'yieldEstimateCounterFruitsPostRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Rendimiento real (Kg/ha)',
//     accessorKey: 'yieldReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
// ];

export const colsYieldCalculatorWinterAndFruit = (specie) => {
  const colsCiruelo = [
    {
      header: 'Temp.',
      accessorKey: 'season',
      maxSize: 80, // max size enforced during resizing
    },
    {
      header: 'Cliente',
      accessorKey: 'client',
      maxSize: 100,
    },
    {
      header: 'Agrícola',
      accessorKey: 'agroBusiness',
      enableSorting: false,
    },
    {
      header: 'Huerto',
      accessorKey: 'orchard',
      maxSize: 130,
    },
    {
      header: 'Centro de Costos',
      accessorKey: 'cc',
      maxSize: 100,
    },
    {
      header: 'Cuartel',
      accessorKey: 'quarter',
    },
    {
      header: 'Especie',
      accessorKey: 'specie',
      maxSize: 100,
      enableSorting: false,
    },
    {
      header: 'Edad',
      accessorKey: 'age',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
      maxSize: 80,
    },
    {
      header: 'Variedad',
      accessorKey: 'variety',
    },
    {
      header: 'Portainjerto',
      accessorKey: 'rootStock',
      maxSize: 110,
    },
    {
      header: 'Superficie',
      accessorKey: 'surface',
      maxSize: 100,
    },
    {
      header: 'Porción Frío acumulada',
      accessorKey: 'pfAcumulated',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
      maxSize: 120,
    },
    {
      header: 'Plantas Totales',
      accessorKey: 'totalPlants',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
    },
    {
      header: 'Plantas/ha',
      accessorKey: 'plantsHa',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      maxSize: 120,
    },
    {
      header: 'Flores / Dardo',
      accessorKey: 'flowersDart',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
      maxSize: 120,
    },
    {
      header: 'Flores / Dardo Objetivo',
      accessorKey: 'flowersDartFinalObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores / Ramilla',
      accessorKey: 'flowersTwig',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores Objetivo/Ha',
      accessorKey: 'flowersHaObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores Pre Poda/ha',
      accessorKey: 'flowersHaPrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },
    {
      header: 'Flores/ Yema/Dardo',
      accessorKey: 'flowersBudDart',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Dardo/ha',
      accessorKey: 'flowersDartHa',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Planta Objetivo',
      accessorKey: 'flowersPlantObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Planta Pre Poda',
      accessorKey: 'flowersPlantPrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Ramilla/ha',
      accessorKey: 'flowersTwigsHa',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Plantas contadas prepoda',
      accessorKey: 'plantCountPre',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Dardos prepoda/planta',
      accessorKey: 'dartPrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Ramillas prepoda/planta',
      accessorKey: 'twigPrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Brindillas Pre Poda',
      accessorKey: 'brindillaPre',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Brindillas Post Poda',
      hidden: true,
      accessorKey: 'brindillaPost',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Yemas / Dardo',
      accessorKey: 'budDart',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Ramillas Objetivo',
      accessorKey: 'twigObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Dardos Objetivo',
      accessorKey: 'dartObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Desyeme',
      accessorKey: 'disbudding',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Dardos Post Poda/Planta',
      accessorKey: 'dartPostPoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Dardo con desyeme',
      accessorKey: 'flowersDartDisbudding',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores Post Poda/ha',
      accessorKey: 'flowersHaPostPoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },
    {
      header: 'Flores/Planta Post Poda',
      accessorKey: 'flowersPlantPostPoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Plantas contadas postpoda',
      accessorKey: 'plantCountPost',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Ramillas Post Poda / Planta',
      accessorKey: 'twigPostPoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    // solo winter

    // solo fruit
    {
      header: 'Frutos/Dardo (estimado)',
      accessorKey: 'fruitsDartEstimate',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Conteo',
      accessorKey: 'fruitsDartCounter',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Máximo',
      accessorKey: 'fruitsDartMax',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Objetivo',
      accessorKey: 'fruitsDartEstimateObjective', //'fruitsDartObjetive',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    // {
    //   header: 'Frutos/Dardo Objetivo',
    //   accessorKey: 'fruitsDartEstimateObjective',
    //   Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    //   enableSorting: false,
    // },
    {
      header: 'Frutos/Dardo Pre Raleo',
      accessorKey: 'fruitsDartPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Ramilla Pre Raleo',
      accessorKey: 'fruitsTwigPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Estructuras Contadas Pre Raleo',
      accessorKey: 'structureCountPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Real',
      accessorKey: 'fruitsDartReal',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/ha (rendimiento)',
      accessorKey: 'fruitsHaYield',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/ha (preraleo)',
      accessorKey: 'fruitsHaPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Planta (preraleo)',
      accessorKey: 'fruitsPlantPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/planta (rendimiento)',
      accessorKey: 'fruitsPlantYield',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Planta Objetivo',
      accessorKey: 'fruitsPlantObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Plantas contadas preraleo',
      accessorKey: 'plantCountPreFruit',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Pauta de raleo',
      accessorKey: 'guidelineThinning',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Plantas contadas postraleo',
      accessorKey: 'plantCountPostFruit',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Post Raleo',
      accessorKey: 'fruitsDartPostRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Planta (postraleo)',
      accessorKey: 'fruitsPlantPostRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Estructuras Contadas Post Raleo',
      accessorKey: 'structureCountPostRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },

    // compartidas
    {
      header: 'Calibre Estimado (grs)',
      accessorKey: 'caliberEstimate',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Calibre Objetivo (grs)',
      accessorKey: 'caliberObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Calibre Real (grs)',
      accessorKey: 'caliberReal',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: '% Cuaja Modelo',
      accessorKey: 'fruitsetEstimate',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: '% Cuaja Objetivo',
      accessorKey: 'fruitSetObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    //
    // solo fruit
    {
      header: '% Cuaja Real',
      accessorKey: 'fruitsSetReal',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: '% Cuaja Real (est. por rdto.)',
      accessorKey: 'fruitsSetRealYield',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Rdto Prom. Histórico',
      accessorKey: 'yieldHistory',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },
    {
      header: 'Rdto estimado Conteo Frutos Pre Raleo (Kg/ha)',
      accessorKey: 'yieldEstimateCounterFruitsPreRaleo',
      // Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
      Cell: ({ renderedCellValue, row }) => {
        let rdtoPreRaleo = null;
        rdtoPreRaleo = renderedCellValue;
        let colorIcon = '#4C5662';
        let iconDeviation = '';
        if (row.original?.yieldEstimatePostPoda && rdtoPreRaleo) {
          const topLimitPreRaleo = row.original?.yieldEstimatePostPoda * 1.1;
          const lowLimitPreRaleo =
            row.original?.yieldEstimatePostPoda -
            row.original?.yieldEstimatePostPoda * 0.1;

          if (rdtoPreRaleo > topLimitPreRaleo) {
            colorIcon = '#1772c1';
            // azul

            iconDeviation = <i className='fas fa-circle'></i>;
          }

          if (rdtoPreRaleo < lowLimitPreRaleo) {
            colorIcon = '#ff6633';

            iconDeviation = <i className='fas fa-circle'></i>;
          }
        }

        return (
          <span style={{ color: colorIcon }}>
            {iconDeviation} {numberFormatDecimals(rdtoPreRaleo, 1)}
          </span>
        );
      },
      enableSorting: false,
    },
    {
      header: 'Rdto objetivo (kg/ha)',
      accessorKey: 'yieldObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },

    // winter
    {
      header: 'Rdto estimado pre poda (kg/ha)',
      accessorKey: 'yieldEstimatePrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },

    {
      header: 'Rdto estimado post poda (Kg/ha)',
      accessorKey: 'yieldEstimatePostPoda',
      Cell: ({ renderedCellValue, row }) => {
        let rdtoPostPoda = null;
        rdtoPostPoda = renderedCellValue;
        let colorIcon = '#4C5662';
        let iconDeviation = '';
        if (row.original?.yieldObjective && rdtoPostPoda) {
          const topLimit = row.original?.yieldObjective * 1.15;
          const lowLimit =
            row.original?.yieldObjective - row.original?.yieldObjective * 0.1;

          // const difference = row.original?.yieldObjective - rdtoPostPoda;

          if (rdtoPostPoda > topLimit || rdtoPostPoda < lowLimit) {
            colorIcon = '#ff6633';

            iconDeviation = <i className='fas fa-circle'></i>;
          }
        }

        return (
          <span style={{ color: colorIcon }}>
            {iconDeviation} {numberFormatDecimals(rdtoPostPoda, 1)}
          </span>
        );
      },
      enableSorting: false,
    },
    // fruit
    {
      header: 'Rdto estimado Conteo Frutos Post Raleo (Kg/ha)',
      accessorKey: 'yieldEstimateCounterFruitsPostRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Rendimiento real (Kg/ha)',
      accessorKey: 'yieldReal',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
  ];
  const colsRest = [
    {
      header: 'Temp.',
      accessorKey: 'season',
      maxSize: 80, // max size enforced during resizing
    },
    {
      header: 'Cliente',
      accessorKey: 'client',
      maxSize: 100,
    },
    {
      header: 'Agrícola',
      accessorKey: 'agroBusiness',
      enableSorting: false,
    },
    {
      header: 'Huerto',
      accessorKey: 'orchard',
      maxSize: 130,
    },
    {
      header: 'Centro de Costos',
      accessorKey: 'cc',
      maxSize: 100,
    },
    {
      header: 'Cuartel',
      accessorKey: 'quarter',
    },
    {
      header: 'Especie',
      accessorKey: 'specie',
      maxSize: 100,
      enableSorting: false,
    },
    {
      header: 'Edad',
      accessorKey: 'age',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
      maxSize: 80,
    },
    {
      header: 'Variedad',
      accessorKey: 'variety',
    },
    {
      header: 'Portainjerto',
      accessorKey: 'rootStock',
      maxSize: 110,
    },
    {
      header: 'Superficie',
      accessorKey: 'surface',
      maxSize: 100,
    },
    {
      header: 'Porción Frío acumulada',
      accessorKey: 'pfAcumulated',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
      maxSize: 120,
    },
    {
      header: 'Plantas Totales',
      accessorKey: 'totalPlants',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
    },
    {
      header: 'Plantas/ha',
      accessorKey: 'plantsHa',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      maxSize: 120,
    },
    {
      header: 'Flores / Dardo',
      accessorKey: 'flowersDart',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
      maxSize: 120,
    },
    {
      header: 'Flores / Dardo Objetivo',
      accessorKey: 'flowersDartFinalObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores / Ramilla',
      accessorKey: 'flowersTwig',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores Objetivo/Ha',
      accessorKey: 'flowersHaObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores Pre Poda/ha',
      accessorKey: 'flowersHaPrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },
    {
      header: 'Flores/ Yema/Dardo',
      accessorKey: 'flowersBudDart',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Dardo/ha',
      accessorKey: 'flowersDartHa',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Planta Objetivo',
      accessorKey: 'flowersPlantObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Planta Pre Poda',
      accessorKey: 'flowersPlantPrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Ramilla/ha',
      accessorKey: 'flowersTwigsHa',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Plantas contadas prepoda',
      accessorKey: 'plantCountPre',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Dardos prepoda/planta',
      accessorKey: 'dartPrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Ramillas prepoda/planta',
      accessorKey: 'twigPrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Brindillas Pre Poda',
      size: 1,
      accessorKey: 'brindillaPre',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Brindillas Post Poda',
      hidden: true,
      accessorKey: 'brindillaPost',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Yemas / Dardo',
      accessorKey: 'budDart',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Ramillas Objetivo',
      accessorKey: 'twigObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Dardos Objetivo',
      accessorKey: 'dartObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Desyeme',
      accessorKey: 'disbudding',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Dardos Post Poda/Planta',
      accessorKey: 'dartPostPoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores/Dardo con desyeme',
      accessorKey: 'flowersDartDisbudding',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Flores Post Poda/ha',
      accessorKey: 'flowersHaPostPoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },
    {
      header: 'Flores/Planta Post Poda',
      accessorKey: 'flowersPlantPostPoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Plantas contadas postpoda',
      accessorKey: 'plantCountPost',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Ramillas Post Poda / Planta',
      accessorKey: 'twigPostPoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    // solo winter

    // solo fruit
    {
      header: 'Frutos/Dardo (estimado)',
      accessorKey: 'fruitsDartEstimate',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Conteo',
      accessorKey: 'fruitsDartCounter',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Objetivo',
      accessorKey: 'fruitsDartEstimateObjective', //'fruitsDartObjetive',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Máximo',
      accessorKey: 'fruitsDartMax',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },

    // {
    //   header: 'Frutos/Dardo Objetivo',
    //   accessorKey: 'fruitsDartEstimateObjective',
    //   Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    //   enableSorting: false,
    // },
    {
      header: 'Frutos/Planta Objetivo',
      accessorKey: 'fruitsPlantObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Pre Raleo',
      accessorKey: 'fruitsDartPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Ramilla Pre Raleo',
      accessorKey: 'fruitsTwigPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Estructuras Contadas Pre Raleo',
      accessorKey: 'structureCountPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Dardo Real',
      accessorKey: 'fruitsDartReal',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/ha (rendimiento)',
      accessorKey: 'fruitsHaYield',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/ha (preraleo)',
      accessorKey: 'fruitsHaPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Planta (preraleo)',
      accessorKey: 'fruitsPlantPreRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/planta (rendimiento)',
      accessorKey: 'fruitsPlantYield',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },

    {
      header: 'Plantas contadas preraleo',
      accessorKey: 'plantCountPreFruit',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Pauta de raleo',
      accessorKey: 'guidelineThinning',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },

    {
      header: 'Frutos/Dardo Post Raleo',
      accessorKey: 'fruitsDartPostRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Estructuras Contadas Post Raleo',
      accessorKey: 'structureCountPostRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Frutos/Planta (postraleo)',
      accessorKey: 'fruitsPlantPostRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Plantas contadas postraleo',
      accessorKey: 'plantCountPostFruit',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimalsCounting(renderedCellValue, 1),
      enableSorting: false,
    },

    // compartidas
    {
      header: 'Calibre Estimado (grs)',
      accessorKey: 'caliberEstimate',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Calibre Objetivo (grs)',
      accessorKey: 'caliberObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Calibre Real (grs)',
      accessorKey: 'caliberReal',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: '% Cuaja Modelo',
      accessorKey: 'fruitsetEstimate',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: '% Cuaja Objetivo',
      accessorKey: 'fruitSetObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    //
    // solo fruit
    {
      header: '% Cuaja Real',
      accessorKey: 'fruitsSetReal',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: '% Cuaja Real (est. por rdto.)',
      accessorKey: 'fruitsSetRealYield',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Rdto Prom. Histórico',
      accessorKey: 'yieldHistory',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },

    {
      header: 'Rdto objetivo (kg/ha)',
      accessorKey: 'yieldObjective',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },

    // winter
    {
      header: 'Rdto estimado pre poda (kg/ha)',
      accessorKey: 'yieldEstimatePrePoda',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 0),
      enableSorting: false,
    },

    {
      header: 'Rdto estimado post poda (Kg/ha)',
      accessorKey: 'yieldEstimatePostPoda',
      Cell: ({ renderedCellValue, row }) => {
        let rdtoPostPoda = null;
        rdtoPostPoda = renderedCellValue;
        let colorIcon = '#4C5662';
        let iconDeviation = '';
        if (row.original?.yieldObjective && rdtoPostPoda) {
          const topLimit = row.original?.yieldObjective * 1.15;
          const lowLimit =
            row.original?.yieldObjective - row.original?.yieldObjective * 0.1;

          // const difference = row.original?.yieldObjective - rdtoPostPoda;

          if (rdtoPostPoda > topLimit || rdtoPostPoda < lowLimit) {
            colorIcon = '#ff6633';

            iconDeviation = <i className='fas fa-circle'></i>;
          }
        }

        return (
          <span style={{ color: colorIcon }}>
            {iconDeviation} {numberFormatDecimals(rdtoPostPoda, 1)}
          </span>
        );
      },
      enableSorting: false,
    },
    // fruit
    {
      header: 'Rdto estimado Conteo Frutos Pre Raleo (Kg/ha)',
      accessorKey: 'yieldEstimateCounterFruitsPreRaleo',
      // Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
      Cell: ({ renderedCellValue, row }) => {
        let rdtoPreRaleo = null;
        rdtoPreRaleo = renderedCellValue;
        let colorIcon = '#4C5662';
        let iconDeviation = '';
        if (row.original?.yieldEstimatePostPoda && rdtoPreRaleo) {
          const topLimitPreRaleo = row.original?.yieldEstimatePostPoda * 1.1;
          const lowLimitPreRaleo =
            row.original?.yieldEstimatePostPoda -
            row.original?.yieldEstimatePostPoda * 0.1;

          if (rdtoPreRaleo > topLimitPreRaleo) {
            colorIcon = '#1772c1';
            // azul

            iconDeviation = <i className='fas fa-circle'></i>;
          }

          if (rdtoPreRaleo < lowLimitPreRaleo) {
            colorIcon = '#ff6633';

            iconDeviation = <i className='fas fa-circle'></i>;
          }
        }

        return (
          <span style={{ color: colorIcon }}>
            {iconDeviation} {numberFormatDecimals(rdtoPreRaleo, 1)}
          </span>
        );
      },
      enableSorting: false,
    },
    {
      header: 'Rdto estimado Conteo Frutos Post Raleo (Kg/ha)',
      accessorKey: 'yieldEstimateCounterFruitsPostRaleo',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
    {
      header: 'Rendimiento real (Kg/ha)',
      accessorKey: 'yieldReal',
      Cell: ({ renderedCellValue }) =>
        numberFormatDecimals(renderedCellValue, 1),
      enableSorting: false,
    },
  ];
  return specie === 'Ciruelo' ? colsCiruelo : colsRest;
};

// export const colsYieldCalculator = [
//   {
//     header: 'Temporada',
//     accessorKey: 'season',
//     maxSize: 90, // max size enforced during resizing
//   },
//   {
//     header: 'Cliente',
//     accessorKey: 'client',
//   },
//   {
//     header: 'Agrícola',
//     accessorKey: 'agroBusiness',
//     enableSorting: false,
//   },
//   {
//     header: 'Centro de Costos',
//     accessorKey: 'cc',
//   },
//   {
//     header: 'Huerto',
//     accessorKey: 'orchard',
//   },
//   {
//     header: 'Cuartel',
//     accessorKey: 'quarter',
//   },
//   {
//     header: 'Especie',
//     accessorKey: 'specie',
//     enableSorting: false,
//   },
//   {
//     header: 'Variedad',
//     accessorKey: 'variety',
//   },
//   {
//     header: 'Portainjerto',
//     accessorKey: 'rootStock',
//   },
//   {
//     header: 'Superficie',
//     accessorKey: 'surface',
//   },
//   {
//     header: 'Plantas Totales',
//     accessorKey: 'totalPlants',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//   },
//   {
//     header: 'Plantas/ha',
//     accessorKey: 'plantsHa',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//   },
//   {
//     header: 'Flores / Dardo',
//     accessorKey: 'flowersDart',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores / Dardo Objetivo',
//     accessorKey: 'flowersDartFinalObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores / Ramilla',
//     accessorKey: 'flowersTwig',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores Objetivo/Ha',
//     accessorKey: 'flowersHaObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores Pre Poda/ha',
//     accessorKey: 'flowersHaPrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/ Yema/Dardo',
//     accessorKey: 'flowersBudDart',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Dardo/ha',
//     accessorKey: 'flowersDartHa',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Planta Objetivo',
//     accessorKey: 'flowersPlantObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Planta Pre Poda',
//     accessorKey: 'flowersPlantPrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Ramilla/ha',
//     accessorKey: 'flowersTwigsHa',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Plantas contadas prepoda',
//     accessorKey: 'plantCountPre',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Dardos prepoda/planta',
//     accessorKey: 'dartPrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Ramillas prepoda/planta',
//     accessorKey: 'twigPrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Yemas / Dardo',
//     accessorKey: 'budDart',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Ramillas Objetivo',
//     accessorKey: 'twigObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Dardos Objetivo',
//     accessorKey: 'dartObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Desyeme',
//     accessorKey: 'disbudding',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Dardos Post Poda/Planta',
//     accessorKey: 'dartPostPoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Dardo con desyeme',
//     accessorKey: 'flowersDartDisbudding',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores Post Poda/ha',
//     accessorKey: 'flowersHaPostPoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//     enableSorting: false,
//   },
//   {
//     header: 'Flores/Planta Post Poda',
//     accessorKey: 'flowersPlantPostPoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Plantas contadas postpoda',
//     accessorKey: 'plantCountPost',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Ramillas Post Poda / Planta',
//     accessorKey: 'twigPostPoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Calibre Estimado (grs)',
//     accessorKey: 'caliberEstimate',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Calibre Objetivo (grs)',
//     accessorKey: 'caliberObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Calibre Real (grs)',
//     accessorKey: 'caliberReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Modelo',
//     accessorKey: 'fruitsetEstimate',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Objetivo',
//     accessorKey: 'fruitSetObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Rdto objetivo (kg/ha)',
//     accessorKey: 'yieldObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//     enableSorting: false,
//   },
//   {
//     header: 'Rdto estimado pre poda (kg/ha)',
//     accessorKey: 'yieldEstimatePrePoda',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
//     enableSorting: false,
//   },

//   {
//     header: 'Rdto estimado post poda (Kg/ha)',
//     accessorKey: 'yieldEstimatePostPoda',
//     Cell: ({ renderedCellValue, row }) => {
//       let rdtoPostPoda = null;
//       rdtoPostPoda = renderedCellValue;
//       let colorIcon = '#4C5662';
//       let iconDeviation = '';
//       if (row.original?.yieldObjective && rdtoPostPoda) {
//         const topLimit = row.original?.yieldObjective * 1.15;
//         const lowLimit =
//           row.original?.yieldObjective - row.original?.yieldObjective * 0.1;

//         // const difference = row.original?.yieldObjective - rdtoPostPoda;

//         if (rdtoPostPoda > topLimit || rdtoPostPoda < lowLimit) {
//           colorIcon = '#ff6633';

//           iconDeviation = <i className='fas fa-circle'></i>;
//         }
//       }

//       return (
//         <span style={{ color: colorIcon }}>
//           {iconDeviation} {numberFormatDecimals(rdtoPostPoda, 1)}
//         </span>
//       );
//     },
//     enableSorting: false,
//   },
//   // HASTA ACA WINTER
//   {
//     header: 'Plantas contadas preraleo',
//     accessorKey: 'plantCountPreFruit',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Planta Objetivo',
//     accessorKey: 'fruitsPlantObjective',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Pre Raleo',
//     accessorKey: 'fruitsDartPreRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Planta (preraleo)',
//     accessorKey: 'fruitsPlantPreRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Plantas contadas postraleo',
//     accessorKey: 'plantCountPostFruit',
//     Cell: ({ renderedCellValue }) =>
//       numberFormatDecimalsCounting(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Post Raleo',
//     accessorKey: 'fruitsDartPostRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Planta (postraleo)',
//     accessorKey: 'fruitsPlantPostRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Objetivo',
//     accessorKey: 'fruitsDartObjetive',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Máximo',
//     accessorKey: 'fruitsDartMax',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Edad',
//     accessorKey: 'age',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },

//   // {
//   //   header: 'Laterales/planta',
//   //   accessorKey: 'arms',
//   //   Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//   //   enableSorting: false,
//   // },

//   {
//     header: 'Yemas / Dardo Real',
//     accessorKey: 'budDartReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },

//   // {
//   //   header: 'frostDart',
//   //   accessorKey: 'frostDart',
//   //   Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//   //   enableSorting: false,
//   // },
//   // {
//   //   header: 'frostTwig',
//   //   accessorKey: 'frostTwig',
//   //   Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//   //   enableSorting: false,
//   // },

//   // {
//   //   header: 'Fecha inicio cosecha',
//   //   accessorKey: 'dateStartHarvest',
//   //   Cell: ({ renderedCellValue }) =>
//   //     renderedCellValue ? dateMonthLibrary(renderedCellValue) : '--',
//   //   enableSorting: false,
//   // },
//   // {
//   //   header: 'Fecha fin cosecha',
//   //   accessorKey: 'dateEndHarvest',
//   //   Cell: ({ renderedCellValue }) =>
//   //     renderedCellValue ? dateMonthLibrary(renderedCellValue) : '--',
//   //   enableSorting: false,
//   // },

//   {
//     header: '% Cuaja Conteos Pre Raleo',
//     accessorKey: 'fruitsSetRealPreRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Real (est. por rdto.)',
//     accessorKey: 'fruitsSetRealYield',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: '% Cuaja Real',
//     accessorKey: 'fruitsSetReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },

//   {
//     header: 'Porción Frío acumulada',
//     accessorKey: 'pfAcumulated',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },

//   // {
//   //   header: 'Duración días de cosecha',
//   //   accessorKey: 'durationHarvestDays',
//   //   Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//   //   enableSorting: false,
//   // },
//   {
//     header: 'Frutos/Dardo (conteo centros frutales)',
//     accessorKey: 'fruitsDartEstimate',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Conteo',
//     accessorKey: 'fruitsDartCounter',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Real',
//     accessorKey: 'fruitsDartReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/Dardo Objetivo',
//     accessorKey: 'fruitsDartEstimateObjective',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/ha (preraleo)',
//     accessorKey: 'fruitsHaPreRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/ha (rendimiento)',
//     accessorKey: 'fruitsHaYield',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Frutos/planta (rendimiento)',
//     accessorKey: 'fruitsPlantYield',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },

//   {
//     header: 'Pauta de raleo',
//     accessorKey: 'guidelineThinning',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },

//   {
//     header: 'Rdto estimado Conteo Frutos Pre Raleo (Kg/ha)',
//     accessorKey: 'yieldEstimateCounterFruitsPreRaleo',
//     // Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     Cell: ({ renderedCellValue, row }) => {
//       let rdtoPreRaleo = null;
//       rdtoPreRaleo = renderedCellValue;
//       let colorIcon = '#4C5662';
//       let iconDeviation = '';
//       if (row.original?.yieldEstimatePostPoda && rdtoPreRaleo) {
//         const topLimitPreRaleo = row.original?.yieldEstimatePostPoda * 1.1;
//         const lowLimitPreRaleo =
//           row.original?.yieldEstimatePostPoda -
//           row.original?.yieldEstimatePostPoda * 0.1;

//         if (rdtoPreRaleo > topLimitPreRaleo) {
//           colorIcon = '#1772c1';
//           // azul

//           iconDeviation = <i className='fas fa-circle'></i>;
//         }

//         if (rdtoPreRaleo < lowLimitPreRaleo) {
//           colorIcon = '#ff6633';

//           iconDeviation = <i className='fas fa-circle'></i>;
//         }
//       }

//       return (
//         <span style={{ color: colorIcon }}>
//           {iconDeviation} {numberFormatDecimals(rdtoPreRaleo, 1)}
//         </span>
//       );
//     },
//     enableSorting: false,
//   },
//   {
//     header: 'Rdto estimado Conteo Frutos Post Raleo (Kg/ha)',
//     accessorKey: 'yieldEstimateCounterFruitsPostRaleo',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
//   {
//     header: 'Rendimiento real (Kg/ha)',
//     accessorKey: 'yieldReal',
//     Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
//     enableSorting: false,
//   },
// ];

export const colsNoVisYieldCalculatorRaleo = [
  'client',
  'agroBusiness',
  'specie',
  'age',
  'rootStock',
  'surface',
  'totalPlants',
  'fruitsDartReal',
  'fruitsHaPreRaleo',
  'fruitsHaYield',
  'fruitsPlantYield',
  // 'fruitsDartMax',
  // 'fruitsDartEstimateObjective',
  // 'fruitsPlantObjective',
  'fruitsDartCounter',
  // 'caliberEstimate',
  // 'caliberObjective',
  // 'caliberReal',
  // 'fruitsetEstimate',
  // 'fruitSetObjective',
  // 'fruitsSetReal',
  'fruitsSetRealYield',
  'yieldReal',
  //ocultas porque no aparecen en el excel
  'brindillaPre',
  'brindillaPost',
  // 'fruitsDartEstimate',
  'yieldHistory',
  //
  'fruitsTwigPreRaleo',
  // 'structureCountPreRaleo',
  'guidelineThinning',
  'pfAcumulated',
  'flowersDart',
  'flowersDartFinalObjective',
  'flowersTwig',
  'flowersHaObjective',
  'flowersHaPrePoda',
  'flowersBudDart',
  'flowersDartHa',
  'flowersPlantObjective',
  'flowersPlantPrePoda',
  'flowersTwigsHa',
  'plantCountPre',
  'dartPrePoda',
  'twigPrePoda',
  'budDart',
  'twigObjective',
  'dartObjective',
  'disbudding',
  'dartPostPoda',
  'flowersDartDisbudding',
  'flowersHaPostPoda',
  'flowersPlantPostPoda',
  'plantCountPost',
  'twigPostPoda',
  'yieldEstimatePrePoda',
  'yieldEstimatePostPoda',
];

export const colsNoVisYieldCalculatorWinter = [
  'client',
  'agroBusiness',
  'specie',
  'age',
  'surface',
  'rootStock',
  'totalPlants',
  'flowersDartHa',
  'flowersDartFinalObjective',
  'flowersTwigsHa',
  'flowersPlantPrePoda',
  'flowersHaPrePoda',
  'flowersPlantObjective',
  'flowersDartDisbudding',
  'flowersPlantPostPoda',
  'caliberEstimate',
  'caliberReal',
  //ocultas por que no aparecen en el excel
  'brindillaPre',
  'brindillaPost',
  'yieldHistory',
  //
  'fruitsetEstimate',
  'fruitsDartEstimate',
  'fruitsDartCounter',
  'fruitsDartMax',
  'fruitsDartObjetive',
  'fruitsDartEstimateObjective',
  'fruitsDartPreRaleo',
  'fruitsTwigPreRaleo',
  'structureCountPreRaleo',
  'fruitsDartReal',
  'fruitsHaYield',
  'fruitsHaPreRaleo',
  'fruitsPlantPreRaleo',
  'fruitsPlantYield',
  'fruitsPlantObjective',
  'plantCountPreFruit',
  'guidelineThinning',
  'plantCountPostFruit',
  'fruitsDartPostRaleo',
  'fruitsPlantPostRaleo',
  'fruitsSetReal',
  'fruitsSetRealYield',
  'yieldEstimateCounterFruitsPreRaleo',
  'yieldEstimateCounterFruitsPostRaleo',
  'yieldReal',
];

// export const colsNoVisYieldCalculator = [
//   'client',
//   'agroBusiness',
//   'orchard',
//   'specie',
//   'surface',
//   'plantsHa',
//   'totalPlants',
//   'arms',
//   'age',
//   'flowersHa',
//   'plantCountPreFruit',
//   'fruitsPlantObjective',
//   'plantCountPostFruit',
//   'fruitsDartPreRaleo',
//   'fruitsPlantPreRaleo',
//   'fruitsDartPostRaleo',
//   'fruitsPlantPostRaleo',
//   'fruitsDartObjetive',
//   'fruitsDartMax',
//   'age',
//   'arms',
//   'budDartReal',
//   'frostTwig',
//   'frostDart',
//   'guidelineThinning',
//   'dateStartHarvest',
//   'dateEndHarvest',
//   'flowersDartDisbudding',
//   'durationHarvestDays',
//   'flowersBudDart',
//   'flowersDart',
//   'flowersDartFinalObjective',
//   'flowersPlant',
//   'flowersPlantObjective',
//   'flowersTwigsHa',
//   'flowersTwig',
//   'flowersDartHa',
//   'fruitsHaPreRaleo',
//   'fruitsHaYield',
//   'fruitsPlantYield',
//   'fruitsSetRealPreRaleo',
//   'fruitsSetRealYield',
//   'fruitsSetReal',
//   'pfAcumulated',
//   'fruitsDartEstimate',
//   'fruitsDartReal',
//   'fruitsDartEstimateObjective',
//   'fruitsHaPreRaleo',
//   'fruitsHaYield',
//   'fruitsPlantYield',
//   'guidelineThinning',
//   'caliberEstimate',
//   'caliberReal',
//   'yieldEstimateCounterFruitsPreRaleo',
//   'yieldEstimateCounterFruitsPostRaleo',
//   'yieldReal',
// ];

export const colsNoVisYieldCalculatorPrune = [
  'client',
  'agroBusiness',
  'orchard',
  'specie',
  'surface',
  'plantsHa',
  'totalPlants',
  'arms',
  'age',
  'flowersHa',
  'plantCountPreFruit',
  'plantCountPostFruit',
  'frostTwig',
  'frostDart',
  'guidelineThinning',
  'dateStartHarvest',
  'dateEndHarvest',
  'flowersDartDisbudding',
  'durationHarvestDays',
  'flowersBudDart',
  'flowersDart',
  'flowersDartFinalObjective',
  'flowersPlant',
  'flowersTwigsHa',
  'flowersTwig',
  'flowersDartHa',
  'fruitsHaPreRaleo',
  'fruitsHaYield',
  'fruitsPlantYield',
  'fruitsSetRealPreRaleo',
  'fruitsSetRealYield',
  'fruitsSetReal',
  'pfAcumulated',
  'fruitsDartEstimate',
  'fruitsDartReal',
  'fruitsDartEstimateObjective',
  'fruitsHaPreRaleo',
  'fruitsHaYield',
  'fruitsPlantYield',
  'fruitsPlantPreRaleo',
  'guidelineThinning',
  'fruitsPlantPostRaleo',
  'caliberEstimate',
  'caliberReal',
  'yieldEstimateCounterFruitsPreRaleo',
  'yieldEstimateCounterFruitsPostRaleo',
  'yieldReal',
];

export const colsFixedYieldCalculator = [
  'season',
  'orchard',
  'cc',
  'quarter',
  'variety',
  // 'rootStock',
];

export const colsBbDormancy = [
  {
    header: 'Temporada',
    accessorKey: 'season',
  },
  {
    header: 'Cliente',
    accessorKey: 'client',
  },
  {
    header: 'Agrícola',
    accessorKey: 'agroBusiness',
    enableSorting: false,
  },
  {
    header: 'Huerto',
    accessorKey: 'orchard',
  },
  {
    header: 'Especie',
    accessorKey: 'specie',
    enableSorting: false,
  },
  {
    header: 'Centro de Costos',
    accessorKey: 'cc',
  },
  {
    header: 'Cuartel',
    accessorKey: 'quarter',
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
  },
  {
    header: 'Portainjerto',
    accessorKey: 'rootStock',
  },
  {
    header: 'Rompedor dormancia 1 ',
    accessorKey: 'breaker1',
  },
  {
    header: 'Dosis rompedor 1 (%)',
    accessorKey: 'dose1',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
  },
  {
    header: 'Adyuvante',
    accessorKey: 'adyuvant',
  },
  {
    header: 'Dosis adyuvante (%)',
    accessorKey: 'doseAdyuvant',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
  },
  {
    header: 'Fecha aplicación rompedor 1',
    accessorKey: 'dateBreaker1',
    Cell: ({ renderedCellValue }) =>
      renderedCellValue ? dateMonthLibrary(renderedCellValue) : '--',
  },
  {
    header: 'Rompedor dormancia 2',
    accessorKey: 'breaker2',
  },
  {
    header: 'Dosis rompedor 2 (%)',
    accessorKey: 'dose2',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
  },
  {
    header: 'Dosis nitrato calcio (%)',
    accessorKey: 'doseNo3Ca',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
  },
  {
    header: 'Fecha aplicación rompedor 2',
    accessorKey: 'dateBreaker2',
    Cell: ({ renderedCellValue }) =>
      renderedCellValue ? dateMonthLibrary(renderedCellValue) : '--',
  },
  {
    header: 'Mojamiento',
    accessorKey: 'wetting',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
  },
];

export const colsNoVisBbDormancy = [];

// prueba de set de cols
export const colsNoVisBbDormancy2 = ['agroBusiness', 'cc'];

export const colsLeafAnalysis = [
  {
    header: 'Temporada',
    accessorKey: 'season',
  },
  {
    header: 'Cliente',
    accessorKey: 'client',
  },
  {
    header: 'Agrícola',
    accessorKey: 'agroBusiness',
    enableSorting: false,
  },
  {
    header: 'Huerto',
    accessorKey: 'orchard',
  },
  {
    header: 'Especie',
    accessorKey: 'specie',
    enableSorting: false,
  },
  {
    header: 'Centro de Costos',
    accessorKey: 'cc',
  },
  {
    header: 'Cuartel',
    accessorKey: 'quarter',
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
  },
  {
    header: 'Portainjerto',
    accessorKey: 'rootStock',
  },
  {
    header: 'Nitrógeno total (%)',
    accessorKey: 'leafN',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Fósforo (%)',
    accessorKey: 'leafP',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Potasio (%)',
    accessorKey: 'leafK',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Calcio (%)',
    accessorKey: 'leafCa',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Magnesio (%)',
    accessorKey: 'leafMg',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Hierro (ppm)',
    accessorKey: 'leafFe',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Manganeso (ppm)',
    accessorKey: 'leafMn',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Zinc (ppm)',
    accessorKey: 'leafZn',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Cobre (ppm)',
    accessorKey: 'leafCu',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Boro (ppm)',
    accessorKey: 'leafBoro',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Clorofila (Spad)',
    accessorKey: 'leafChlorophyll',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Sodio (ppm)',
    accessorKey: 'leafNa',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Cloro (%)',
    accessorKey: 'leafCl',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
];

export const colsNoVisLeafAnalysis = [];

export const colsRootReservoir = [
  {
    header: 'Temporada',
    accessorKey: 'season',
  },
  {
    header: 'Cliente',
    accessorKey: 'client',
  },
  {
    header: 'Agrícola',
    accessorKey: 'agroBusiness',
    enableSorting: false,
  },
  {
    header: 'Huerto',
    accessorKey: 'orchard',
  },
  {
    header: 'Especie',
    accessorKey: 'specie',
    enableSorting: false,
  },
  {
    header: 'Centro de Costos',
    accessorKey: 'cc',
  },
  {
    header: 'Cuartel',
    accessorKey: 'quarter',
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
  },
  {
    header: 'Portainjerto',
    accessorKey: 'rootStock',
  },
  {
    header: 'Arginina (mg/g)',
    accessorKey: 'reservesArginine',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Almidón (mg/g)',
    accessorKey: 'reservesStarch',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
];

export const colsNoVisRootReservoir = [];

export const colsSoilAnalysis = [
  {
    header: 'Temporada',
    accessorKey: 'season',
  },
  {
    header: 'Cliente',
    accessorKey: 'client',
  },
  {
    header: 'Agrícola',
    accessorKey: 'agroBusiness',
    enableSorting: false,
  },
  {
    header: 'Huerto',
    accessorKey: 'orchard',
  },
  {
    header: 'Especie',
    accessorKey: 'specie',
    enableSorting: false,
  },
  {
    header: 'Centro de Costos',
    accessorKey: 'cc',
  },
  {
    header: 'Cuartel',
    accessorKey: 'quarter',
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
  },
  {
    header: 'Portainjerto',
    accessorKey: 'rootStock',
  },
  {
    header: 'N disponible agosto (mg/kg)',
    accessorKey: 'soil1Ndisp',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Suma Bases (cmol/kg)',
    accessorKey: 'soil1SumBases',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'CICE (cmol/kg)',
    accessorKey: 'soil1CiCe',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Saturación Al (%)',
    accessorKey: 'soil1SaturationAl',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Saturación K (%)',
    accessorKey: 'soil1SaturationK',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Saturación Ca (%)',
    accessorKey: 'soil1SaturationCa',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Saturación Mg (%)',
    accessorKey: 'soil1SaturationMg',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Relación Ca/Mg',
    accessorKey: 'soil1RatioCaMg',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Relación K/Mg',
    accessorKey: 'soil1RatioKMg',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'N disponible enero (mg/kg)',
    accessorKey: 'soil2Ndisp',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'PH suelo agosto',
    accessorKey: 'soil1Ph',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'MO agosto (%)',
    accessorKey: 'soil1MatOrg',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'CE ext agosto (mS/cm)',
    accessorKey: 'soil1Ce',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'NH4 disponible agosto (mg/kg)',
    accessorKey: 'soil1Nh4',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'NO3 disponible agosto (mg/kg)',
    accessorKey: 'soil1No3',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'P-Olsen (mg/kg)',
    accessorKey: 'soil1P',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'K interc (cmol/kg)',
    accessorKey: 'soil1KInterc',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'K (mg/kg)',
    accessorKey: 'soil1KDisp',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Ca inter (cmol/kg)',
    accessorKey: 'soil1CaInterc',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Mg interc (cmol/kg)',
    accessorKey: 'soil1MgInterc',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Na interc (cmol/kg)',
    accessorKey: 'soil1NaInterc',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Azufre (mg/kg)',
    accessorKey: 'soil1SDisp',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Al interc (cmol/kg)',
    accessorKey: 'soil1AlInterc',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Fe (mg/kg)',
    accessorKey: 'soil1Fe',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Mn (mg/kg)',
    accessorKey: 'soil1Mn',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Zn (mg/kg)',
    accessorKey: 'soil1Zn',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Cu (mg/kg)',
    accessorKey: 'soil1Cu',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'B (mg/kg)',
    accessorKey: 'soil1Boro',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'PH suelo enero',
    accessorKey: 'soil2Ph',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'CE ext enero (mS/cm)',
    accessorKey: 'soil2Ce',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'NH4 disponible enero (mg/kg)',
    accessorKey: 'soil2Nh4Disp',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'NO3 disponible enero (mg/kg)',
    accessorKey: 'soil2No3Disp',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
];

export const colsNoVisSoilAnalysis = [];

export const colsWaterAnalysis = [
  {
    header: 'Temporada',
    accessorKey: 'season',
  },
  {
    header: 'Cliente',
    accessorKey: 'client',
  },
  {
    header: 'Agrícola',
    accessorKey: 'agroBusiness',
  },
  {
    header: 'Huerto',
    accessorKey: 'orchard',
  },
  {
    header: 'Especie',
    accessorKey: 'specie',
  },
  {
    header: 'Centro de Costos',
    accessorKey: 'cc',
  },
  {
    header: 'Cuartel',
    accessorKey: 'quarter',
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
  },
  {
    header: 'Portainjerto',
    accessorKey: 'rootStock',
  },
  {
    header: 'PH agua',
    accessorKey: 'waterPh',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'CE agua (dS/m)',
    accessorKey: 'waterCondElectric',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Salinidad (mg/l)',
    accessorKey: 'waterSalinity',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'RAS',
    accessorKey: 'waterRas',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Dureza (GHF)',
    accessorKey: 'waterHardness',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'N-NH4 (mg/L)',
    accessorKey: 'waterNNh4',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'N-NO3 (mg/L)',
    accessorKey: 'waterNNo3',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Bicarbonatos (mg/l)',
    accessorKey: 'waterBicarbonates',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Ca (mg/L)',
    accessorKey: 'waterCa',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Mg (mg/L)',
    accessorKey: 'waterMg',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
  {
    header: 'Cl (meq/ L)',
    accessorKey: 'waterCl',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 1),
  },
];

export const colsNoVisWaterAnalysis = [];

export const colsCounterQuarterV2 = [
  {
    id: 'productiveUnit',
    header: 'Caracterización',
    visibleInShowHideMenu: false,
    columns: [
      {
        header: 'Huerto',
        accessorKey: 'orchard',
        visibleInShowHideMenu: false,
        maxSize: 120,
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Unidad Prod.',
        accessorKey: 'quarter',
        visibleInShowHideMenu: false,
        maxSize: 130,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              <div>
                <b>{row.original.quarter}</b>
              </div>
              <div>
                {row.original.cc} -{row.original.variety}
              </div>
            </>
          );
        },
      },
      {
        header: 'Superf.',
        accessorKey: 'surface',
        maxSize: 100,
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 2),
        enableSorting: true,
      },
      {
        header: 'Total Plantas',
        accessorKey: 'totalPlants',
        maxSize: 120,
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: true,
      },
    ],
  },

  {
    id: 'prePoda',
    header: 'Pre Poda',
    columns: [
      {
        header: 'Avances',
        id: 'progressPrePoda',
        columns: [
          // {
          //   header: '% Superf. contada',
          //   accessorKey: 'surfacePercentagePrePoda',
          //   Cell: ({ renderedCellValue }) =>
          //     numberFormatDecimalsCounting(renderedCellValue, 1),
          //   enableSorting: false,
          // },
          {
            header: 'Nº Plantas cont.',
            maxSize: 120,
            accessorKey: 'countPlantsPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 0),
            enableSorting: false,
          },
          {
            header: '% Plantas cont.',
            maxSize: 120,
            accessorKey: 'percentageAdvanceCountPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 3),
            enableSorting: false,
          },
          {
            header: '% Conteo Grilla',
            maxSize: 120,
            accessorKey: 'percentageAdvanceCountGridPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
          },
        ],
      },
      {
        header: 'Estructura reproductiva',
        id: 'reproductivePrePoda',
        columns: [
          {
            header: 'Flores /ha',
            accessorKey: 'flowersHaPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Prom. Dardos',
            maxSize: 150,
            accessorKey: 'dartPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
          {
            header: 'Prom. Ramillas',
            maxSize: 150,
            accessorKey: 'twigPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
        ],
      },
    ],
  },
  {
    id: 'postPoda',
    header: 'Post Poda',
    columns: [
      {
        header: 'Avances',
        id: 'progressPostPoda',
        columns: [
          {
            header: 'Nº Plantas cont',
            accessorKey: 'countPlantsPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 0),
            enableSorting: false,
            maxSize: 120,
          },
          {
            header: '% Plantas cont',
            accessorKey: 'percentageAdvanceCountPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            maxSize: 120,
          },
        ],
      },
      {
        header: 'Estructura reproductiva',
        id: 'reproductivePostPoda',
        columns: [
          {
            header: 'Promedio Dardo Obj.',
            accessorKey: 'dartObjective',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
          {
            header: 'Promedio Ramilla Obj.',
            accessorKey: 'twigObjective',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
          {
            header: 'Dardos Post Poda',
            accessorKey: 'dartPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Ramillas Post Poda',
            accessorKey: 'twigPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Flores /ha',
            accessorKey: 'flowersHaPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
        ],
      },
    ],
  },
  {
    id: 'preRaleo',
    header: 'Pre Raleo',
    columns: [
      {
        header: 'Avances',
        id: 'progressPreRaleo',
        columns: [
          {
            header: 'Nº Plantas cont.',
            accessorKey: 'countPlantsPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 0),
            enableSorting: false,
            maxSize: 120,
          },
          {
            header: '% Plantas cont.',
            accessorKey: 'percentageAdvanceCountPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 3),
            enableSorting: false,
            maxSize: 120,
          },
          {
            header: 'Estructuras cont.',
            accessorKey: 'structureCountPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 3),
            enableSorting: false,
            maxSize: 120,
          },
        ],
      },
      {
        header: 'Estructura Reproductiva',
        id: 'fruitPreRaleo',
        columns: [
          {
            header: 'Frutos Planta',
            accessorKey: 'fruitsFinalPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. frutos/dardos',
            accessorKey: 'fruitsDartPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Ramillas',
            accessorKey: 'fruitsTwigPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Estruc. Dardos',
            accessorKey: 'percentageDartStructurePreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Prom. Estruc. Ramillas',
            accessorKey: 'percentageTwigStructurePreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Prom. dardos/dardos planta',
            accessorKey: 'fruitsTotalDartPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. ramillas/ramillas planta',
            accessorKey: 'fruitsTotalTwigPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Frutos/Dardos/Ramillas',
            accessorKey: 'fruitsPreRaleoDartTwig',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Conteo frutos/dardos planta',
            accessorKey: 'finalFruitsDartPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Conteo frutos/ramilla planta',
            accessorKey: 'finalFruitsTwigPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
        ],
      },
    ],
  },
  {
    id: 'postRaleo',
    header: 'Post Raleo',
    columns: [
      {
        header: 'Avances',
        id: 'progressPostRaleo',
        columns: [
          {
            header: 'Nº Plantas cont.',
            accessorKey: 'countPlantsPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 0),
            enableSorting: false,
            maxSize: 120,
          },
          {
            header: '% Plantas cont.',
            accessorKey: 'percentageAdvanceCountPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            maxSize: 120,
          },
          {
            header: 'Estructuras cont.',
            accessorKey: 'structureCountPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 3),
            enableSorting: false,
            maxSize: 120,
          },
        ],
      },
      {
        header: 'Estructura Reproductiva',
        id: 'fruitPostRaleo',
        columns: [
          {
            header: 'Frutos Planta',
            accessorKey: 'fruitsFinalPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. frutos/dardos',
            accessorKey: 'fruitsDartPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. frutos/ramillas',
            accessorKey: 'fruitsTwigPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Estructura Dardos',
            accessorKey: 'percentageDartStructurePostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Prom. Estructura Ramillas',
            accessorKey: 'percentageTwigStructurePostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Prom. dardos/dardos planta',
            accessorKey: 'fruitsTotalDartPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. ramillas/ramillas planta',
            accessorKey: 'fruitsTotalTwigPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Frutos/Dardos/Ramillas',
            accessorKey: 'fruitsPostRaleoDartTwig',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Conteo frutos/dardos planta',
            accessorKey: 'finalFruitsDartPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Conteo frutos/ramillas planta',
            accessorKey: 'finalFruitsTwigPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
        ],
      },
    ],
  },
  {
    id: 'uniteInfo',
    header: 'Análisis de Yema',
    columns: [
      {
        header: 'Yemas dardo',
        accessorKey: 'budDart',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
        maxSize: 120,
      },
      {
        header: 'Yemas dardo real',
        accessorKey: 'budDartReal',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
        maxSize: 120,
      },
      {
        header: 'Flores dardo',
        accessorKey: 'flowersDart',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
        maxSize: 120,
      },
      {
        header: 'Flores ramilla',
        accessorKey: 'flowersTwig',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
        maxSize: 120,
      },
    ],
  },
];

export const colsCounterQuarterV2_Ciruelo = [
  {
    id: 'productiveUnit',
    header: 'Caracterización',
    visibleInShowHideMenu: false,
    columns: [
      {
        header: 'Huerto',
        accessorKey: 'orchard',
        maxSize: 120,
        visibleInShowHideMenu: false,
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Unidad Prod.',
        accessorKey: 'quarter',
        maxSize: 130,
        visibleInShowHideMenu: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              <div>
                <b>{row.original.quarter}</b>
              </div>
              <div>
                {row.original.cc} -{row.original.variety}
              </div>
            </>
          );
        },
      },
      {
        header: 'Superf.',
        maxSize: 100,
        accessorKey: 'surface',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 2),
        enableSorting: true,
      },
      {
        header: 'Total Plantas',
        accessorKey: 'totalPlants',
        maxSize: 120,
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: true,
      },
    ],
  },
  {
    id: 'prePoda',
    header: 'Pre Poda',
    columns: [
      {
        header: 'Avances',
        id: 'progressPrePoda',
        columns: [
          // {
          //   header: '% Superf. contada',
          //   accessorKey: 'surfacePercentagePrePoda',
          //   Cell: ({ renderedCellValue }) =>
          //     numberFormatDecimalsCounting(renderedCellValue, 1),
          //   enableSorting: false,
          // },
          {
            header: 'Nº Plantas cont.',
            accessorKey: 'countPlantsPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 0),
            enableSorting: false,
            maxSize: 120,
          },
          {
            header: '% Plantas cont',
            accessorKey: 'percentageAdvanceCountPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            maxSize: 120,
          },
          {
            header: '% Conteo Grilla',
            accessorKey: 'percentageAdvanceCountGridPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            maxSize: 120,
          },
        ],
      },
      {
        header: 'Estructura reproductiva',
        id: 'reproductivePrePoda',
        columns: [
          {
            header: 'Flores /ha',
            accessorKey: 'flowersHaPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 130,
          },
          {
            header: 'Promedio Dardos',
            accessorKey: 'dartPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 130,
          },
          {
            header: 'Promedio Ramillas',
            accessorKey: 'twigPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 130,
          },
          {
            header: 'Promedio Brindillas',
            accessorKey: 'brindillaPrePoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
        ],
      },
    ],
  },
  {
    id: 'postPoda',
    header: 'Post Poda',
    columns: [
      {
        header: 'Avances',
        id: 'progressPostPoda',
        columns: [
          {
            header: 'Nº Plantas cont.',
            accessorKey: 'countPlantsPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 0),
            enableSorting: false,
            maxSize: 130,
          },
          {
            header: '% Plantas cont.',
            accessorKey: 'percentageAdvanceCountPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            maxSize: 130,
          },
        ],
      },
      {
        header: 'Estructura reproductiva',
        id: 'reproductivePostPoda',
        columns: [
          {
            header: 'Promedio Dardo Obj.',
            accessorKey: 'dartObjective',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
          {
            header: 'Promedio Ramilla Obj.',
            accessorKey: 'twigObjective',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
          {
            header: 'Dardos Post Poda',
            accessorKey: 'dartPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
          {
            header: 'Ramillas Post Poda',
            accessorKey: 'twigPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
          {
            header: 'Brindillas Post Poda',
            accessorKey: 'brindillaPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
          {
            header: 'Flores /ha',
            accessorKey: 'flowersHaPostPoda',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
        ],
      },
    ],
  },
  {
    id: 'preRaleo',
    header: 'Pre Raleo',
    columns: [
      {
        header: 'Avances',
        id: 'progressPreRaleo',
        columns: [
          {
            header: 'Nº Plantas cont.',
            accessorKey: 'countPlantsPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 0),
            enableSorting: false,
          },
          {
            header: '% Plantas cont.',
            accessorKey: 'percentageAdvanceCountPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
          },
          {
            header: 'Estructuras cont.',
            accessorKey: 'structureCountPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 3),
            enableSorting: false,
          },
        ],
      },
      {
        header: 'Estructura Reproductiva',
        id: 'fruitPreRaleo',
        columns: [
          {
            header: 'Frutos Planta',
            accessorKey: 'fruitsFinalPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. frutos/dardos',
            accessorKey: 'fruitsDartPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Ramillas',
            accessorKey: 'fruitsTwigPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Estruc. Dardos',
            accessorKey: 'percentageDartStructurePreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Prom. Estruc. Ramillas',
            accessorKey: 'percentageTwigStructurePreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Prom. dardos/dardos planta',
            accessorKey: 'fruitsTotalDartPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. ramillas/ramillas planta',
            accessorKey: 'fruitsTotalTwigPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Frutos/Dardos/Ramillas',
            accessorKey: 'fruitsPreRaleoDartTwig',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Conteo frutos/dardos planta',
            accessorKey: 'finalFruitsDartPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Conteo frutos/ramilla planta',
            accessorKey: 'finalFruitsTwigPreRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
          },
        ],
      },
    ],
  },
  {
    id: 'postRaleo',
    header: 'Post Raleo',
    columns: [
      {
        header: 'Avances',
        id: 'progressPostRaleo',
        columns: [
          {
            header: 'Nº Plantas cont.',
            accessorKey: 'countPlantsPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 0),
            enableSorting: false,
            maxSize: 120,
          },
          {
            header: '% Plantas cont.',
            accessorKey: 'percentageAdvanceCountPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            maxSize: 120,
          },
          {
            header: 'Estructuras cont.',
            accessorKey: 'structureCountPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 3),
            enableSorting: false,
            maxSize: 120,
          },
        ],
      },
      {
        header: 'Estructura Reproductiva',
        id: 'fruitPostRaleo',
        columns: [
          {
            header: 'Frutos Planta',
            accessorKey: 'fruitsFinalPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. frutos/dardos',
            accessorKey: 'fruitsDartPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. frutos/ramillas',
            accessorKey: 'fruitsTwigPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Estructura Dardos',
            accessorKey: 'percentageDartStructurePostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Prom. Estructura Ramillas',
            accessorKey: 'percentageTwigStructurePostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 2),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 150,
          },
          {
            header: 'Prom. dardos/dardos planta',
            accessorKey: 'fruitsTotalDartPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. ramillas/ramillas planta',
            accessorKey: 'fruitsTotalTwigPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Prom. Frutos/Dardos/Ramillas',
            accessorKey: 'fruitsPostRaleoDartTwig',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Conteo frutos/dardos planta',
            accessorKey: 'finalFruitsDartPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
          {
            header: 'Conteo frutos/ramillas planta',
            accessorKey: 'finalFruitsTwigPostRaleo',
            Cell: ({ renderedCellValue }) =>
              numberFormatDecimalsCounting(renderedCellValue, 1),
            enableSorting: false,
            enableColumnActions: false,
            maxSize: 120,
          },
        ],
      },
    ],
  },

  {
    id: 'uniteInfo',
    header: 'Análisis de Yema',
    columns: [
      {
        header: 'Yemas dardo',
        accessorKey: 'budDart',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Yemas dardo real',
        accessorKey: 'budDartReal',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Flores dardo',
        accessorKey: 'flowersDart',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Ramillas dardo',
        accessorKey: 'flowersTwig',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
    ],
  },
];

export const colsCounterQuarterV2Fixed = [
  'productiveUnit',
  'orchard',
  'quarter',
  'surface',
  'totalPlants',
];

export const colsCounterQuarter = [
  {
    id: 'productiveUnit',
    header: 'Unidad',
    visibleInShowHideMenu: false,
    columns: [
      {
        header: 'Unidad Productiva',
        accessorKey: 'cc',
        visibleInShowHideMenu: false,
      },
      {
        header: 'Total Plantas',
        accessorKey: 'plantCountTotal',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Rend. Histórico',
        accessorKey: 'plantCountTotal2',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
    ],
  },
  {
    id: 'prePoda',
    header: 'Pre Poda',
    columns: [
      {
        header: 'Plantas contadas',
        accessorKey: 'plantCountPre',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Dardos',
        accessorKey: 'dartPre',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Ramillas',
        accessorKey: 'twigPre',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Rendimiento',
        accessorKey: 'yieldPre',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
      },
    ],
  },
  {
    id: 'objective',
    header: 'Objetivo',
    columns: [
      {
        header: 'Dardos',
        accessorKey: 'dartObjective',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Ramillas',
        accessorKey: 'twigObjective',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Rendimiento',
        accessorKey: 'yieldObjective',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
      },
    ],
  },
  {
    id: 'postPoda',
    header: 'Post Poda',
    columns: [
      {
        header: 'Plantas contadas',
        accessorKey: 'plantCountPost',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Dardos',
        accessorKey: 'dartPost',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Ramillas',
        accessorKey: 'twigPost',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Rendimiento',
        accessorKey: 'yieldPost',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
      },
    ],
  },
  {
    id: 'preRaleo',
    header: 'Pre Raleo',
    columns: [
      {
        header: 'Plantas contadas',
        accessorKey: 'plantCountPreRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Frutos',
        accessorKey: 'fruitPreRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Rendimiento',
        accessorKey: 'yieldPreRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
      },
    ],
  },
  {
    id: 'postRaleo',
    header: 'Post Raleo',
    columns: [
      {
        header: 'Plantas contadas',
        accessorKey: 'plantCountPostRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Frutos',
        accessorKey: 'fruitPostRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
      {
        header: 'Rendimiento',
        accessorKey: 'yieldPostRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
      },
    ],
  },
  {
    id: 'yield',
    header: 'Rendimiento',
    columns: [
      {
        header: 'Rdto. Productor',
        accessorKey: 'yieldProducer',
      },
      {
        header: 'Desviación',
        accessorKey: 'deviation',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: false,
      },
    ],
  },
];

export const colsNoVisCounterQuarter = [];

export const colsNoVisCounterCharacterization = [
  'productiveUnit',
  'orchard',
  'quarter',
  'surface',
  'totalPlants',
];

export const colsNoVisCounterInformation = [
  'uniteInfo',
  'budDart',
  'budDartReal',
  'flowersDart',
  'flowersTwig',
];

export const colsNoVisCounterProductiveUnit = [
  'reproductivePrePoda',
  'flowersHaPrePoda',
  'dartPrePoda',
  'twigPrePoda',
  'brindillaPrePoda',
  'reproductivePostPoda',
  'dartObjective',
  'twigObjective',
  'dartPostPoda',
  'twigPostPoda',
  'brindillaPostPoda',
  'flowerTwig',
  'flowersHaPostPoda',
  'fruitPreRaleo',
  'fruitsPlantPreRaleo',
  'fruitPostRaleo',
  'fruitsPlantPostRaleo',
];

export const colsNoVisCounterProgress = [
  'progressPrePoda',
  'countPlantsPrePoda',
  'percentageAdvanceCountPrePoda',
  'percentageAdvanceCountGridPrePoda',
  'progressPostPoda',
  'countPlantsPostPoda',
  'percentageAdvanceCountPostPoda',
  'progressPreRaleo',
  'countPlantsPreRaleo',
  'percentageAdvanceCountPreRaleo',
  'progressPostRaleo',
  'countPlantsPostRaleo',
  'percentageAdvanceCountPostRaleo',
];

export const colsNoVisCounterPrePoda = [
  'prePoda',
  'progressPrePoda',
  'countPlantsPrePoda',
  'percentageAdvanceCountPrePoda',
  'percentageAdvanceCountGridPrePoda',
  'reproductivePrePoda',
  'flowersHaPrePoda',
  'dartPrePoda',
  'twigPrePoda',
];

export const colsNoVisCounterPrePoda_Ciruelo = [
  'prePoda',
  'progressPrePoda',
  'countPlantsPrePoda',
  'percentageAdvanceCountPrePoda',
  'percentageAdvanceCountGridPrePoda',
  'reproductivePrePoda',
  'flowersHaPrePoda',
  'dartPrePoda',
  'twigPrePoda',
  'brindillaPrePoda',
];

export const colsNoVisCounterPostPoda = [
  'postPoda',
  'progressPostPoda',
  'countPlantsPostPoda',
  'percentageAdvanceCountPostPoda',
  'reproductivePostPoda',
  'dartObjective',
  'twigObjective',
  'dartPostPoda',
  'twigPostPoda',
  'flowersHaPostPoda',
];

export const colsNoVisCounterPostPoda_Ciruelo = [
  'postPoda',
  'progressPostPoda',
  'countPlantsPostPoda',
  'percentageAdvanceCountPostPoda',
  'reproductivePostPoda',
  'dartObjective',
  'twigObjective',
  'dartPostPoda',
  'twigPostPoda',
  'brindillaPostPoda',
  'flowersHaPostPoda',
];

export const colsNoVisCounterPreRaleo = [
  'preRaleo',
  'progressPreRaleo',
  'countPlantsPreRaleo',
  'percentageAdvanceCountPreRaleo',
  'fruitPreRaleo',
  'fruitsPlantPreRaleo',
  'fruitsDartPreRaleo',
  'fruitsTwigPreRaleo',
  'structureCountPreRaleo',
  'percentageDartStructurePreRaleo',
  'percentageTwigStructurePreRaleo',
  'fruitsTotalDartPreRaleo',
  'fruitsTotalTwigPreRaleo',
  'fruitsPreRaleoDartTwig',
  'fruitsPlantPreRaleo',
  'fruitsFinalPreRaleo',
  'finalFruitsDartPreRaleo',
  'finalFruitsTwigPreRaleo',
];

export const colsNoVisCounterPostRaleo = [
  'postRaleo',
  'progressPostRaleo',
  'countPlantsPostRaleo',
  'percentageAdvanceCountPostRaleo',
  'fruitPostRaleo',
  'fruitsDartPostRaleo',
  'fruitsTwigPostRaleo',
  'fruitsPlantPostRaleo',
  'structureCountPostRaleo',
  'percentageDartStructurePostRaleo',
  'percentageTwigStructurePostRaleo',
  'fruitsTotalDartPostRaleo',
  'fruitsTotalTwigPostRaleo',
  'fruitsPostRaleoDartTwig',
  'fruitsPlantPostRaleo',
  'fruitsFinalPostRaleo',
  'finalFruitsDartPostRaleo',
  'finalFruitsTwigPostRaleo',
];

export const colsCounterPlant = [
  {
    header: 'Huerto',
    accessorKey: 'orchard',
  },
  {
    header: 'Centro de Costos',
    accessorKey: 'cc',
  },
  {
    header: 'Cuartel',
    accessorKey: 'quarter',
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
  },
  {
    header: 'Portainjerto',
    accessorKey: 'rootStock',
  },
  {
    header: 'Usuario',
    accessorKey: 'fullnameUser',
    enableSorting: false,
    // permite ocultar opciones para la columna
    enableColumnActions: false,
  },
  {
    header: 'Tipo de Conteo',
    accessorKey: 'counterType',
    Cell: ({ renderedCellValue }) => dashDelete(renderedCellValue),
    enableSorting: true,
  },
  {
    header: 'Fecha',
    accessorKey: 'date',
    enableSorting: true,
  },
  {
    header: 'Hora',
    accessorKey: 'hour',
    enableSorting: false,
  },
  {
    header: 'Hilera',
    accessorKey: 'rowNumber',
    enableSorting: true,
  },
  {
    header: 'Planta',
    accessorKey: 'plantNumber',
    enableSorting: true,
  },
  {
    header: 'Dardos Prepoda',
    accessorKey: 'dartPre',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Ramillas Prepoda',
    accessorKey: 'twigPre',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Dardos Postpoda',
    accessorKey: 'dartPost',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Ramillas Postpoda',
    accessorKey: 'twigPost',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Frutos Preraleo',
    accessorKey: 'fruitPre',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Frutos Postraleo',
    accessorKey: 'fruitPost',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Prom. frutos/dardos Preraleo',
    accessorKey: 'fruitsTotalDartPreRaleo',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Prom. frutos/ramillas Preraleo',
    accessorKey: 'fruitsTotalTwigPreRaleo',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Prom. frutos/dardos Postraleo',
    accessorKey: 'fruitsTotalDartPostRaleo',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Prom. frutos/ramillas Postraleo',
    accessorKey: 'fruitsTotalTwigPostRaleo',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
];

export const colsCounterPlant_Ciruelo = [
  {
    header: 'Huerto',
    accessorKey: 'orchard',
  },
  {
    header: 'Centro de Costos',
    accessorKey: 'cc',
  },
  {
    header: 'Cuartel',
    accessorKey: 'quarter',
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
  },
  {
    header: 'Portainjerto',
    accessorKey: 'rootStock',
  },
  {
    header: 'Usuario',
    accessorKey: 'fullnameUser',
    enableSorting: false,
    // permite ocultar opciones para la columna
    enableColumnActions: false,
  },
  {
    header: 'Tipo de Conteo',
    accessorKey: 'counterType',
    Cell: ({ renderedCellValue }) => dashDelete(renderedCellValue),
    enableSorting: true,
  },
  {
    header: 'Fecha',
    accessorKey: 'date',
    enableSorting: true,
  },
  {
    header: 'Hilera',
    accessorKey: 'rowNumber',
    enableSorting: true,
  },
  {
    header: 'Planta',
    accessorKey: 'plantNumber',
    enableSorting: true,
  },
  {
    header: 'Dardos Prepoda',
    accessorKey: 'dartPre',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Ramillas Prepoda',
    accessorKey: 'twigPre',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Brindillas Prepoda',
    accessorKey: 'brindillaPre',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: false,
  },
  {
    header: 'Dardos Postpoda',
    accessorKey: 'dartPost',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Ramillas Postpoda',
    accessorKey: 'twigPost',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Brindillas Postpoda',
    accessorKey: 'brindillaPost',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Frutos Preraleo',
    accessorKey: 'fruitPre',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Frutos Postraleo',
    accessorKey: 'fruitPost',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Prom. frutos/dardos Preraleo',
    accessorKey: 'fruitsTotalDartPreRaleo',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Prom. frutos/ramillas Preraleo',
    accessorKey: 'fruitsTotalTwigPreRaleo',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Prom. frutos/dardos Postraleo',
    accessorKey: 'fruitsTotalDartPostRaleo',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
  {
    header: 'Prom. frutos/ramillas Postraleo',
    accessorKey: 'fruitsTotalTwigPostRaleo',
    Cell: ({ renderedCellValue }) =>
      numberFormatDecimalsCounting(renderedCellValue, 1),
    enableSorting: true,
  },
];

export const colsNoVisCounterPlant = [
  'orchard',
  'rootStock',
  'fruitPost',
  'fruitPre',
];
export const colsNoVisPlantPrePoda = ['dartPre', 'twigPre'];
export const colsNoVisPlantPrePoda_Ciruelo = [
  'dartPre',
  'twigPre',
  'brindillaPre',
];
export const colsNoVisPlantPostPoda = ['dartPost', 'twigPost'];
export const colsNoVisPlantPostPoda_Ciruelo = [
  'dartPost',
  'twigPost',
  'brindillaPost',
];
export const colsNoVisPlantPreRaleo = [
  'fruitPre',
  'fruitsTotalDartPreRaleo',
  'fruitsTotalTwigPreRaleo',
];
export const colsNoVisPlantPostRaleo = [
  'fruitPost',
  'fruitsTotalDartPostRaleo',
  'fruitsTotalTwigPostRaleo',
];

export const colsBudAnalysis = [
  // {
  //   header: 'Temporada',
  //   accessorKey: 'season',
  //   enableEditing: false,
  // },
  {
    header: 'Huerto',
    accessorKey: 'orchard',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: false,
  },
  {
    header: 'Centro Costo',
    accessorKey: 'cc',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: false,
  },
  {
    header: 'Cuartel',
    accessorKey: 'quarter',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: false,
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: true,
  },
  {
    header: 'PortaInjerto',
    accessorKey: 'rootStock',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: false,
  },
  {
    header: 'Flores/dardo',
    accessorKey: 'flowersDart',
    enableSorting: false,
    enableGrouping: false,
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'number',
      inputProps: {
        step: 0.1,
        // deshabilita el scroll del mouse
        onWheel: (e) => e.target.blur(),
      },
    }),
  },
  {
    header: 'Flores/ramilla',
    accessorKey: 'flowersTwig',
    enableSorting: false,
    enableGrouping: false,
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'number',
      inputProps: {
        step: 0.1,
        onWheel: (e) => e.target.blur(),
      },
    }),
  },
  {
    header: 'Yemas/dardo',
    accessorKey: 'budDart',
    enableSorting: false,
    enableGrouping: false,
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'number',
      inputProps: {
        step: 0.1,
        onWheel: (e) => e.target.blur(),
      },
    }),
  },
  {
    header: 'Yemas/dardo Real',
    accessorKey: 'budDartReal',
    enableSorting: false,
    enableGrouping: false,
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'number',
      inputProps: {
        step: 0.1,
        // deshabilita el scroll del mouse
        onWheel: (e) => e.target.blur(),
      },
    }),
  },
];

export const colsDormancyAnalysis = [
  // {
  //   header: 'Temporada',
  //   accessorKey: 'season',
  //   enableEditing: false,
  // },
  {
    header: 'Huerto',
    accessorKey: 'orchard',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: false,
  },
  {
    header: 'Centro Costo',
    accessorKey: 'cc',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: false,
  },
  {
    header: 'Cuartel',
    accessorKey: 'quarter',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: false,
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: true,
  },
  {
    header: 'Porta Injerto',
    accessorKey: 'rootStock',
    enableSorting: false,
    enableEditing: false,
    enableGrouping: false,
  },

  {
    header: 'Rompedor dormancia 1 ',
    accessorKey: 'breaker1',
    enableGrouping: false,
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'string',
      // inputProps: {
      //   step: 0.1,
      // },
    }),
  },
  {
    header: 'Dosis rompedor 1 (%)',
    accessorKey: 'dose1',
    enableGrouping: false,
    // Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'number',
      inputProps: {
        step: 0.01,
        onWheel: (e) => e.target.blur(),
      },
    }),
  },
  {
    header: 'Adyuvante',
    accessorKey: 'adyuvant',
    enableGrouping: false,
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'string',
      inputProps: {
        // step: 0.1,
      },
    }),
  },
  {
    header: 'Dosis adyuvante (%)',
    accessorKey: 'doseAdyuvant',
    enableGrouping: false,
    // Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'number',
      inputProps: {
        step: 0.01,
        onWheel: (e) => e.target.blur(),
      },
    }),
  },
  {
    header: 'Fecha aplicación rompedor 1',
    accessorKey: 'dateBreaker1',
    enableGrouping: false,
    Cell: ({ renderedCellValue }) =>
      renderedCellValue ? dateFrontConvert(renderedCellValue) : '--',
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'date',
      inputProps: {
        // step: 0.1,
      },
    }),
  },
  {
    header: 'Rompedor dormancia 2',
    accessorKey: 'breaker2',
    enableGrouping: false,
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'string',
      inputProps: {
        // step: 0.1,
      },
    }),
  },
  {
    header: 'Dosis rompedor 2 (%)',
    accessorKey: 'dose2',
    enableGrouping: false,
    // Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'number',
      inputProps: {
        step: 0.01,
        onWheel: (e) => e.target.blur(),
      },
    }),
  },
  {
    header: 'Dosis nitrato calcio (%)',
    accessorKey: 'doseNo3Ca',
    enableGrouping: false,
    // Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'number',
      inputProps: {
        step: 0.01,
        onWheel: (e) => e.target.blur(),
      },
    }),
  },
  {
    header: 'Fecha aplicación rompedor 2',
    accessorKey: 'dateBreaker2',
    enableGrouping: false,
    Cell: ({ renderedCellValue }) =>
      renderedCellValue ? dateFrontConvert(renderedCellValue) : '--',
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'date',
      inputProps: {
        // step: 0.1,
      },
    }),
  },
  {
    header: 'Mojamiento',
    accessorKey: 'wetting',
    enableGrouping: false,
    // Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 2),
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'number',
      inputProps: {
        step: 0.01,
        onWheel: (e) => e.target.blur(),
      },
    }),
  },
];

export const colsScheduleTable = [
  {
    header: 'Huerto',
    accessorKey: 'orchard',
  },
  {
    header: 'Especie',
    accessorKey: 'specie',
  },
  {
    header: 'Variedad',
    accessorKey: 'variety',
  },
  {
    header: 'Fecha',
    accessorKey: 'date',
    Cell: ({ renderedCellValue }) => dateFrontConvert(renderedCellValue),
    enableSorting: true,
  },
  {
    header: 'Cosecha',
    accessorKey: 'kilos',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
  },
  {
    header: 'Versión',
    accessorKey: 'version',
    Cell: ({ renderedCellValue }) => numberFormatDecimals(renderedCellValue, 0),
  },
];

export const colsNoVisScheduleTable = [];

export const colsCounterOrchards = [
  {
    id: 'productiveUnit',
    header: 'Caracterización',
    visibleInShowHideMenu: false,
    columns: [
      {
        header: 'Huerto',
        accessorKey: 'orchard',
        visibleInShowHideMenu: false,
      },
      {
        header: 'Superficie',
        accessorKey: 'surface',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 2),
        enableSorting: true,
      },
      {
        header: 'Total Plantas',
        accessorKey: 'totalPlants',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 0),
        enableSorting: true,
      },
    ],
  },
  {
    id: 'progressPrePoda',
    header: 'Avances',
    columns: [
      {
        header: 'Plantas contadas',
        accessorKey: 'countPlantsPrePoda',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: '% Plantas Contadas',
        accessorKey: 'percentageAdvanceCountPrePoda',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 3),
        enableSorting: true,
      },
      {
        header: 'Cuarteles Contados',
        accessorKey: 'countProductiveUnitUnicPrePoda',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Cuarteles No Contados',
        accessorKey: 'noCountProductiveUnitUnicPrePoda',
        // Cell: ({ renderedCellValue }) =>
        //   numberFormatDecimalsCounting(renderedCellValue, 1),
        noExport: true,

        Cell: ({ renderedCellValue, row }) => {
          let stageNoCount = 0;
          let totalQuarters =
            row.original?.stageCountPostPoda +
            row.original?.stageCountPostRaleo +
            row.original?.stageCountPreRaleo +
            row.original?.stageCountPrePoda +
            row.original?.noStageCount;

          // console.log(row.original, '------', totalQuarters);

          if (totalQuarters > 0) {
            stageNoCount =
              totalQuarters - row.original?.countProductiveUnitUnicPrePoda;

            stageNoCount = stageNoCount < 0 ? 0 : stageNoCount;
          }

          return numberFormatDecimals(stageNoCount, 0);
        },

        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: '% Conteo de Grilla',
        accessorKey: 'percentageAdvanceGridPrePoda',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: true,
      },
    ],
  },
  {
    id: 'progressPostPoda',
    header: 'Avances',
    columns: [
      {
        header: 'Plantas contadas',
        accessorKey: 'countPlantsPostPoda',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: '% Plantas Contadas',
        accessorKey: 'percentageAdvanceCountPostPoda',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 3),
        enableSorting: true,
      },
      {
        header: 'Cuarteles Contados',
        accessorKey: 'countProductiveUnitUnicPostPoda',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Cuarteles No Contados',
        accessorKey: 'noCountProductiveUnitUnicPostPoda',
        noExport: true,
        // Cell: ({ renderedCellValue }) =>
        //   numberFormatDecimalsCounting(renderedCellValue, 1),

        Cell: ({ renderedCellValue, row }) => {
          let stageNoCount = 0;
          let totalQuarters =
            row.original?.stageCountPostPoda +
            row.original?.stageCountPostRaleo +
            row.original?.stageCountPreRaleo +
            row.original?.stageCountPrePoda +
            row.original?.noStageCount;
          if (totalQuarters > 0) {
            stageNoCount =
              totalQuarters - row.original?.countProductiveUnitUnicPostPoda;
          }

          return numberFormatDecimals(stageNoCount, 0);
        },

        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: '% Conteo de Grilla',
        accessorKey: 'percentageAdvanceGridPostPoda',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: true,
      },
    ],
  },
  {
    id: 'progressPreRaleo',
    header: 'Avances',
    columns: [
      {
        header: 'Plantas contadas',
        accessorKey: 'countPlantsPreRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: '% Plantas Contadas',
        accessorKey: 'percentageAdvanceCountPreRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 3),
        enableSorting: true,
      },
      {
        header: 'Estructuras Contadas',
        accessorKey: 'structureCountPreRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Cuarteles Contados',
        accessorKey: 'countProductiveUnitUnicPreRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Cuarteles No Contados',
        accessorKey: 'noCountProductiveUnitUnicPreRaleo',
        noExport: true,
        // Cell: ({ renderedCellValue }) =>
        //   numberFormatDecimalsCounting(renderedCellValue, 1),

        Cell: ({ renderedCellValue, row }) => {
          let stageNoCount = 0;
          let totalQuarters =
            row.original?.stageCountPostPoda +
            row.original?.stageCountPostRaleo +
            row.original?.stageCountPreRaleo +
            row.original?.stageCountPrePoda +
            row.original?.noStageCount;
          if (totalQuarters > 0) {
            stageNoCount =
              totalQuarters - row.original?.countProductiveUnitUnicPreRaleo;
          }

          return numberFormatDecimals(stageNoCount, 0);
        },

        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: '% Conteo de Grilla',
        accessorKey: 'percentageAdvanceGridPreRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: true,
      },
    ],
  },
  {
    id: 'progressPostRaleo',
    header: 'Avances',
    columns: [
      {
        header: 'Plantas contadas',
        accessorKey: 'countPlantsPostRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: '% Plantas Contadas',
        accessorKey: 'percentageAdvanceCountPostRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 3),
        enableSorting: true,
      },
      {
        header: 'Estructuras Contadas',
        accessorKey: 'structureCountPostRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Cuarteles Contados',
        accessorKey: 'countProductiveUnitUnicPostRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: 'Cuarteles No Contados',
        accessorKey: 'noCountProductiveUnitUnicPostRaleo',
        noExport: true,
        // Cell: ({ renderedCellValue }) =>
        //   numberFormatDecimalsCounting(renderedCellValue, 1),

        Cell: ({ renderedCellValue, row }) => {
          let stageNoCount = 0;
          let totalQuarters =
            row.original?.stageCountPostPoda +
            row.original?.stageCountPostRaleo +
            row.original?.stageCountPreRaleo +
            row.original?.stageCountPrePoda +
            row.original?.noStageCount;
          if (totalQuarters > 0) {
            stageNoCount =
              totalQuarters - row.original?.countProductiveUnitUnicPostRaleo;
          }

          return numberFormatDecimals(stageNoCount, 0);
        },

        enableSorting: false,
        enableColumnActions: false,
      },
      {
        header: '% Conteo de Grilla',
        accessorKey: 'percentageAdvanceGridPostRaleo',
        Cell: ({ renderedCellValue }) =>
          numberFormatDecimalsCounting(renderedCellValue, 1),
        enableSorting: true,
      },
    ],
  },
];

export const colsNoVisCounterOrchardsPrePoda = [
  'progressPrePoda',
  'countPlantsPrePoda',
  'percentageAdvanceCountPrePoda',
  'noCountProductiveUnitUnicPrePoda',
  'countProductiveUnitUnicPrePoda',
  'percentageAdvanceGridPrePoda',
];

export const colsNoVisCounterOrchardsPostPoda = [
  'progressPostPoda',
  'countPlantsPostPoda',
  'percentageAdvanceCountPostPoda',
  'countProductiveUnitUnicPostPoda',
  'noCountProductiveUnitUnicPostPoda',
  'percentageAdvanceGridPostPoda',
];

export const colsNoVisCounterOrchardsPreRaleo = [
  'progressPreRaleo',
  'countPlantsPreRaleo',
  'percentageAdvanceCountPreRaleo',
  'countProductiveUnitUnicPreRaleo',
  'noCountProductiveUnitUnicPreRaleo',
  'percentageAdvanceGridPreRaleo',
  'structureCountPreRaleo',
];

export const colsNoVisCounterOrchardsPostRaleo = [
  'progressPostRaleo',
  'countPlantsPostRaleo',
  'percentageAdvanceCountPostRaleo',
  'countProductiveUnitUnicPostRaleo',
  'noCountProductiveUnitUnicPostRaleo',
  'percentageAdvanceGridPostRaleo',
  'structureCountPostRaleo',
];
